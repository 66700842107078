import { Component, Inject, OnInit } from '@angular/core';
import { concatMap, take } from 'rxjs/operators';
import { BusyService } from '@unleashed/services/common';
import { BRAND_OPTIONS, BrandOptions } from '@unleashed/common/config';
import { AttendeeApiService } from '@unleashed/api/booking';
import { AttendeeRestrictions, Booking, NavigationSteps } from '@unleashed/models/booking';
import { BookingSessionService } from '@unleashed/services/booking';
import { PhoneNumberPipe } from '@unleashed/common/pipes';
import { GoogleTagManagerService } from "angular-google-tag-manager";

@Component({
  selector: 'ua-attendees',
  templateUrl: './attendees.component.html',
  styleUrls: ['./attendees.component.scss']
})
export class AttendeesComponent implements OnInit {

  booking?: Booking;
  participantCount = 0;
  adultParticipantCount = 0;
  attendeeRestrictions?: AttendeeRestrictions;
  phoneNumber?: string;
  attendeesText: string;
  adultParticipantPrice = 0;
  subtotal?: number;
  selectionChanged = false;
  attendeesLoaded = false;
  error?: number;
  phoneNumberPipe = new PhoneNumberPipe();
  brandId?: number;
  constructor(
    private sessionService: BookingSessionService,
    @Inject(BRAND_OPTIONS) private brandOptions: BrandOptions,
    private attendeeApiService: AttendeeApiService,
    private busyService: BusyService,
    private gtmService: GoogleTagManagerService
  ) {
    this.attendeesText = brandOptions.attendeesText;
    this.brandId = brandOptions.brandId;
  }

  ngOnInit(): void {

    this.sessionService.getBooking()
      .pipe(
        take(1),
        concatMap(booking => {
          this.booking = booking;
          if (!booking.baseParticipantCount) {
            this.selectionChanged = true;
          }
          return this.attendeeApiService.getAttendeeRestrictions(booking);
        }))
      .subscribe(attendeeRestrictions => {
        this.attendeeRestrictions = attendeeRestrictions;
        if (this.booking?.baseParticipantCount !== undefined && this.booking?.extraParticipantCount !== undefined) {
          this.participantCount = this.booking?.baseParticipantCount + this.booking?.extraParticipantCount;
        }
        else {
          this.participantCount = this.attendeeRestrictions.minQuantity;
        }
        this.adultParticipantCount = this.booking?.adultParticipantCount && this.attendeeRestrictions.adultParticipantPrice !== undefined
          ? this.booking?.adultParticipantCount
          : 0;
        this.adultParticipantPrice = this.attendeeRestrictions?.adultParticipantPrice ?? 0 > 0
          ? (this.attendeeRestrictions.adultParticipantPrice ?? 0)
          : ((this.attendeeRestrictions.participantPrice ?? 0) / 2);

        if (!attendeeRestrictions.participantPrice) {
          this.attendeeRestrictions.maxQuantity = this.attendeeRestrictions.minQuantity;
        }
        this.attendeesLoaded = true;
      }, err => this.error = err.status);

    this.sessionService.getSession()
      .subscribe(session => {
        this.subtotal = session.subtotal;
        const park = session.park;
        if (!park?.enrolledGLT && park?.phoneNumber) {
          this.phoneNumber = this.phoneNumberPipe.transform(park?.phoneNumber);
        } else {
          this.phoneNumber = this.phoneNumberPipe.transform(this.brandOptions.phoneNumber);
        }
      });
  }

  back(): void {
    if (!this.booking) {
      return;
    }
    this.booking.step = 4;
    this.saveAndNavigate(this.booking, NavigationSteps.Hangouts);
  }

  next(): void {
    if (!this.booking) {
      return;
    }
    this.booking.step = 5;
    this.saveAndNavigate(this.booking, NavigationSteps.AddOns);
  }

  saveAndNavigate(booking: Booking, step: NavigationSteps): void {
    if (!this.attendeeRestrictions) {
      return;
    }   

    if (!this.selectionChanged) {
      this.sessionService.navigateToStep(step);
      return;
    }

    this.busyService.set('attendees', true);

    booking.bookingItems = [];
    booking.baseParticipantCount = this.attendeeRestrictions.minQuantity;
    booking.extraParticipantCount = this.participantCount - this.attendeeRestrictions.minQuantity;
    booking.adultParticipantCount = this.adultParticipantCount;

    this.sessionService.upsertCart(booking)
      .subscribe(
        () => {
          this.busyService.set('attendees', false);
          this.sessionService.navigateToStep(step);
        },
        err => {
          this.error = err.status;
          this.busyService.set('attendees', false);
        });

  }

  addParticipant(): void {
    if (!this.subtotal || !this.attendeeRestrictions?.participantPrice) {
      return;
    }

    this.gtmService.pushTag({
      'event': 'attendeesSelectClicked',
      'numberOfKids': this.attendeeRestrictions.minQuantity,
      'numberOfAdults': this.adultParticipantCount,
      'numberOfAdditional': this.participantCount - this.attendeeRestrictions.minQuantity
    });
    
    this.selectionChanged = true;
    this.participantCount++;
    this.subtotal += this.attendeeRestrictions.participantPrice;
    this.sessionService.setSubtotal(this.subtotal);
  }

  addAdultParticipant(): void {
    if (!this.subtotal) {
      return;
    }
    this.selectionChanged = true;
    this.adultParticipantCount++;
    this.subtotal += this.adultParticipantPrice;
    this.sessionService.setSubtotal(this.subtotal);
  }

  subtractParticipant(): void {
    if (!this.subtotal || !this.attendeeRestrictions?.participantPrice) {
      return;
    }
    this.selectionChanged = true;
    this.participantCount--;
    this.subtotal -= this.attendeeRestrictions.participantPrice;
    this.sessionService.setSubtotal(this.subtotal);
  }

  subtractAdultParticipant(): void {
    if (!this.subtotal) {
      return;
    }
    this.selectionChanged = true;
    this.adultParticipantCount--;
    this.subtotal -= this.adultParticipantPrice;
    this.sessionService.setSubtotal(this.subtotal);
  }

}
